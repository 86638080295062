import {
  AGAINST_MEETING_RESULT_CHOICES,
  AGAINST_MEETING_STATUS_CHOICES,
  ASSESSMENT_CHOICES,
  BOOLEAN_CHOICES,
  COMFORT_CLASS_CHOICES,
  DELETED_CHOICES,
  FINANCIAL_UNIT_FUND_CHOICES,
  KIND_CHOICES,
  MEETING_STATUS_CHOICES,
  ROSREEST_STATUS_CHOICES,
  ROSREEST_TYPE_CHOICES,
  TAG_CHOICES,
} from "constants/choices"
import { FilterType } from "types"

export const IS_DELETED_FILTER: FilterType = {
  slug: "is_deleted",
  name: "Показывать удаленные объекты",
  _type: "choices",
  choices: DELETED_CHOICES,
  defaultValue: "false",
}

export const ORDERING_FILTER: FilterType = {
  slug: "ordering",
  name: "Сортировка",
  _type: null,
  defaultValue: "-created",
}

const BUILDING_FILTER: FilterType = {
  slug: "building__uid",
  name: "Здание",
  _type: "entitylink",
  entityLinkTo: "building",
}

const PREMISE_FILTERS: FilterType[] = [
  BUILDING_FILTER,
  IS_DELETED_FILTER,
  ORDERING_FILTER,
]

const BUILDING_FILTERS: FilterType[] = [
  {
    slug: "building_type__uid",
    name: "Тип объекта",
    _type: "entitylink",
    entityLinkTo: "buildingtype",
  },
  {
    slug: "division__uid",
    name: "Регион управления",
    _type: "entitylink",
    entityLinkTo: "division",
  },
  {
    slug: "sector__uid",
    name: "Филиал управления",
    _type: "entitylink",
    entityLinkTo: "sector",
  },
  {
    slug: "management_service__uid",
    name: "Территориальное отделение управления",
    _type: "entitylink",
    entityLinkTo: "managementservice",
  },
  {
    slug: "exploitation_division__uid",
    name: "Дивизион эксплуатации",
    _type: "entitylink",
    entityLinkTo: "exploitationdivision",
  },
  {
    slug: "exploitation_sector__uid",
    name: "Участок эксплуатации",
    _type: "entitylink",
    entityLinkTo: "exploitationsector",
  },
  {
    slug: "exploitation__uid",
    name: "Служба эксплуатации",
    dependencies: ["sector__uid"],
    _type: "entitylink",
    entityLinkTo: "exploitation",
  },
  {
    slug: "management_company__uid",
    name: "Управляющая компания",
    _type: "entitylink",
    entityLinkTo: "managementcompany",
  },
  {
    slug: "comfort_class",
    name: "Класс фонда",
    _type: "choices",
    choices: [{ value: null, label: "Не важно" }, ...COMFORT_CLASS_CHOICES],
    defaultValue: null,
  },
  {
    slug: "living_complex__uid",
    name: "Жилищный комплекс",
    _type: "entitylink",
    entityLinkTo: "mdslivingcomplex",
  },
  {
    slug: "contract_tags__overlap",
    name: "Теги",
    _type: "tags",
    choices: TAG_CHOICES,
  },
  {
    slug: "special_fund",
    name: "Спецфонд",
    _type: "choices",
    choices: [{ value: null, label: "Не важно" }, ...BOOLEAN_CHOICES],
    defaultValue: null,
  },
  {
    slug: "product_fund",
    name: "Фонд продукт",
    _type: "choices",
    choices: [{ value: null, label: "Не важно" }, ...BOOLEAN_CHOICES],
    defaultValue: null,
  },
  {
    slug: "renovation_fund",
    name: "Реновация",
    _type: "choices",
    choices: [{ value: null, label: "Не важно" }, ...BOOLEAN_CHOICES],
    defaultValue: null,
  },
  {
    slug: "created__gte",
    name: "Создано",
    _type: "date",
  },
  {
    slug: "updated__gte",
    name: "Обновлено",
    _type: "date",
  },
  IS_DELETED_FILTER,
  ORDERING_FILTER,
]

export const LIVINGENTRANCE_FILTERS: FilterType[] = [
  BUILDING_FILTER,
  {
    slug: "building__division__uid",
    name: "Регион управления",
    _type: "entitylink",
    entityLinkTo: "division",
  },
  {
    slug: "building__exploitation_sector__uid",
    name: "Участок эксплуатации",
    _type: "entitylink",
    entityLinkTo: "exploitationsector",
  },
  {
    slug: "building__exploitation__uid",
    name: "Служба эксплуатации",
    dependencies: ["building__sector__uid"],
    _type: "entitylink",
    entityLinkTo: "exploitation",
  },
  {
    slug: "building__management_company__uid",
    name: "Управляющая компания",
    _type: "entitylink",
    entityLinkTo: "managementcompany",
  },
  IS_DELETED_FILTER,
  ORDERING_FILTER,
]

const ELEVATOR_FILTERS: FilterType[] = [
  BUILDING_FILTER,
  {
    slug: "building_part__uid",
    name: "Подъезд",
    dependencies: ["building__uid"],
    _type: "entitylink",
    entityLinkTo: "buildingpart",
  },
  {
    slug: "building__division__uid",
    name: "Регион управления",
    _type: "entitylink",
    entityLinkTo: "division",
  },
  {
    slug: "building__exploitation_sector__uid",
    name: "Участок эксплуатации",
    _type: "entitylink",
    entityLinkTo: "exploitationsector",
  },
  {
    slug: "building__exploitation__uid",
    name: "Служба эксплуатации",
    dependencies: ["building__sector__uid"],
    _type: "entitylink",
    entityLinkTo: "exploitation",
  },
  {
    slug: "building__management_company__uid",
    name: "Управляющая компания",
    _type: "entitylink",
    entityLinkTo: "managementcompany",
  },
  {
    slug: "manufacturing_plant__uid",
    name: "Завод изготовитель",
    _type: "entitylink",
    entityLinkTo: "manufacturingplant",
  },
  {
    slug: "elevator_model__uid",
    name: "Модель лифта",
    _type: "entitylink",
    entityLinkTo: "elevatormodel",
  },
  IS_DELETED_FILTER,
  ORDERING_FILTER,
]

const WHEELCHAIRELEVATOR_FILTERS: FilterType[] = [
  BUILDING_FILTER,
  {
    slug: "building_part__uid",
    name: "Подъезд",
    dependencies: ["building__uid"],
    _type: "entitylink",
    entityLinkTo: "buildingpart",
  },
  {
    slug: "building__division__uid",
    name: "Регион управления",
    _type: "entitylink",
    entityLinkTo: "division",
  },
  {
    slug: "building__exploitation_sector__uid",
    name: "Участок эксплуатации",
    _type: "entitylink",
    entityLinkTo: "exploitationsector",
  },
  {
    slug: "building__exploitation__uid",
    name: "Служба эксплуатации",
    dependencies: ["building__sector__uid"],
    _type: "entitylink",
    entityLinkTo: "exploitation",
  },
  {
    slug: "building__management_company__uid",
    name: "Управляющая компания",
    _type: "entitylink",
    entityLinkTo: "managementcompany",
  },
  {
    slug: "manufacturing_plant__uid",
    name: "Завод изготовитель",
    _type: "entitylink",
    entityLinkTo: "manufacturingplant",
  },
  {
    slug: "elevator_model__uid",
    name: "Модель лифта",
    _type: "entitylink",
    entityLinkTo: "elevatormodel",
  },
  IS_DELETED_FILTER,
  ORDERING_FILTER,
]

const ROSREESTR_RIGHT_FILTERS: FilterType[] = [
  {
    slug: "statement__building__uid",
    name: "Здание",
    _type: "entitylink",
    entityLinkTo: "building",
  },
  {
    slug: "statement__status",
    name: "Статус выписки",
    _type: "choices",
    choices: ROSREEST_STATUS_CHOICES,
    defaultValue: "parsed",
  },
  {
    slug: "order_user__uid",
    name: "Заказчик",
    _type: "entitylink",
    entityLinkTo: "user",
  },
  IS_DELETED_FILTER,
  ORDERING_FILTER,
]

const ROSREESTR_STATEMENT_FILTERS: FilterType[] = [
  BUILDING_FILTER,
  {
    slug: "status",
    name: "Статус выписки",
    _type: "choices",
    choices: ROSREEST_STATUS_CHOICES,
    defaultValue: "parsed",
  },
  {
    slug: "type",
    name: "Тип выписки",
    _type: "choices",
    choices: [{ value: null, label: "Не важно" }, ...ROSREEST_TYPE_CHOICES],
    defaultValue: null,
  },
  {
    slug: "order_user__uid",
    name: "Заказчик",
    _type: "entitylink",
    entityLinkTo: "user",
  },
  IS_DELETED_FILTER,
  {
    slug: "is_actual",
    name: "Показывать актуальные выписки",
    _type: "choices",
    defaultValue: "true",
    choices: [
      {
        label: "Показывать только актуальные",
        value: "true",
      },
      {
        label: "Показывать все",
        value: null,
      },
    ],
  },
  { ...ORDERING_FILTER, defaultValue: "building,number" },
]

const MEETING_FILTERS: FilterType[] = [
  {
    slug: "curator__uid",
    name: "Куратор",
    _type: "entitylink",
    entityLinkTo: "financialemployment",
  },
  {
    slug: "kind",
    name: "Вид",
    _type: "choices",
    choices: [{ value: null, label: "Не важно" }, ...KIND_CHOICES],
    defaultValue: null,
  },
  {
    slug: "status",
    name: "Статус",
    _type: "choices",
    choices: MEETING_STATUS_CHOICES,
  },
  IS_DELETED_FILTER,
  ORDERING_FILTER,
]

const MEETING_REQUEST_FILTERS: FilterType[] = [
  BUILDING_FILTER,
  {
    slug: "responsible__uid",
    name: "Ответственный",
    _type: "entitylink",
    entityLinkTo: "employee",
  },
  {
    slug: "initiator__uid",
    name: "Заполняющий",
    _type: "entitylink",
    entityLinkTo: "employee",
  },
  {
    slug: "against_meeting_status",
    name: "текущий статус ОСС против нас",
    _type: "choices",
    choices: [
      { value: null, label: "Не важно" },
      ...AGAINST_MEETING_STATUS_CHOICES,
    ],
    defaultValue: null,
  },
  {
    slug: "against_meeting_result",
    name: "текущий результат ОСС против нас",
    _type: "choices",
    choices: AGAINST_MEETING_RESULT_CHOICES,
  },
  IS_DELETED_FILTER,
  ORDERING_FILTER,
]

const FINANCIAL_UNIT_ASSESSMENT_FILTERS: FilterType[] = [
  {
    slug: "financial_unit__uid",
    name: "Подразделение",
    _type: "entitylink",
    entityLinkTo: "financialunit",
  },
  {
    slug: "financial_unit__owner__employee",
    name: "Владелец",
    _type: "entitylink",
    entityLinkTo: "employee",
  },
  {
    slug: "fund",
    name: "Фонд",
    _type: "choices",
    choices: [
      { value: null, label: "Не важно" },
      ...FINANCIAL_UNIT_FUND_CHOICES,
    ],
    defaultValue: null,
  },
  {
    slug: "assessment",
    name: "Оценка",
    _type: "choices",
    choices: ASSESSMENT_CHOICES,
  },
  IS_DELETED_FILTER,
  ORDERING_FILTER,
]

export const ENTITIES_FILTERS: Record<string, FilterType[]> = {
  building: BUILDING_FILTERS,
  inventorybuilding: BUILDING_FILTERS,
  livingentrance: LIVINGENTRANCE_FILTERS,
  elevator: ELEVATOR_FILTERS,
  wheelchairelevator: WHEELCHAIRELEVATOR_FILTERS,
  project: LIVINGENTRANCE_FILTERS,
  rosreestrright: ROSREESTR_RIGHT_FILTERS,
  rosreestrstatement: ROSREESTR_STATEMENT_FILTERS,
  meeting: MEETING_FILTERS,
  premise: PREMISE_FILTERS,
  meetingrequest: MEETING_REQUEST_FILTERS,
  financialunitassessment: FINANCIAL_UNIT_ASSESSMENT_FILTERS,
}
