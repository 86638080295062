export const TAG_CHOICES = [
  {
    value: "exploitation",
    label: "Эксплуатация",
  },
  {
    value: "management",
    label: "Управление",
  },
  {
    value: "funds_collecting",
    label: "Сбор средств через ЛК Клиента",
  },
  {
    value: "customer_service",
    label: "Услуга обращения в ЛК",
  },
  {
    value: "contact_center_service",
    label: "Услуга контактного центра",
  },
  {
    value: "receipt",
    label: "Приемка",
  },
  {
    value: "dvz",
    label: "услуга ДВЗ",
  },
  {
    value: "cession",
    label: "Цессия",
  },
]

export const DELETED_CHOICES = [
  {
    label: "Показывать только актуальные",
    value: "false",
  },
  {
    label: "Показывать только удаленные",
    value: "true",
  },
  {
    label: "Показывать все",
    value: null,
  },
]

export const BOOLEAN_CHOICES = [
  { value: "true", label: "Да" },
  { value: "false", label: "Нет" },
]

export const MONTH_CHOICES = [
  { value: "january", label: "Январь" },
  { value: "february", label: "Февраль" },
  { value: "march", label: "Март" },
  { value: "april", label: "Апрель" },
  { value: "may", label: "Май" },
  { value: "june", label: "Июнь" },
  { value: "july", label: "Июль" },
  { value: "august", label: "Август" },
  { value: "september", label: "Сентябрь" },
  { value: "october", label: "Октябрь" },
  { value: "november", label: "Ноябрь" },
  { value: "december", label: "Декабрь" },
]

export const PAY_SERVICE_CHOICES = [
  { value: "builder", label: "Застройщик" },
  { value: "management_company", label: "Управляющая компания" },
]

export const KIND_CHOICES = [
  { value: "movement", label: "Перевод" },
  { value: "development", label: "Развитие" },
  { value: "defense", label: "Защита" },
  { value: "counter-meeting", label: "Контр-собрание" },
]

export const COMPETITION_STATUS_CHOICES = [
  {
    value: "decision_to_participate_competition",
    label: "Принято решение об участии в конкурсе",
  },
  {
    value: "submitting_payment_docs_to_TESSA",
    label: "Документы на оплату внесены в TESSA",
  },
  {
    value: "preparing_application_for_competition",
    label: "Подготовлена заявка на участие в конкурсе",
  },
  {
    value: "application_security_payment",
    label: "Оплачено обеспечение заявки, заявка еще не подана",
  },
  { value: "application_submission", label: "Заявка подана" },
  {
    value: "changes_to_application",
    label: "Поданы изменения к заявке",
  },
  {
    value: "application_revoked_from_competition",
    label: "Заявка отозвана с конкурса",
  },
  { value: "opening_envelopes", label: "Вскрытие конвертов" },
  {
    value: "canceled_delays_with_oss",
    label: "Отменен: затягивание под ОСС",
  },
  {
    value: "never_take_place_low_turnout",
    label: "Не состоялся: снижение 10.1%",
  },
  {
    value: "canceled_another_reason",
    label: "Отменен: другая причина",
  },
  { value: "win_competition", label: "Конкурс выигран" },
  {
    value: "prepare_for_send_insurance_and_contract",
    label: "Подготовка страховки и проекта договора для отправки",
  },
  {
    value: "insurance_and_contract_transferred",
    label: "Проект договора и страховка переданы организатору конкурса",
  },
]

export const COMFORT_CLASS_CHOICES = [
  { value: "business", label: "Бизнес" },
  { value: "comfort", label: "Комфорт" },
  { value: "standard", label: "Стандарт" },
]

export const ACCESSION_STATUS_CHOICES = [
  { value: "purchase", label: "Покупка" },
  { value: "move_in", label: "Заселение" },
  { value: "tender", label: "Конкурс" },
  { value: "meeting", label: "Общее Собрание" },
  {
    value: "omsu_resolution",
    label: "Постановление местного самоуправления",
  },
  { value: "du", label: "Договор управления (Застройщик)" },
  { value: "court_decision", label: "Решение суда" },
  { value: "interim_management", label: "Временное управление" },
  { value: "succession", label: "Правопреемство" },
]

export const OUTFLOW_STATUS_CHOICES = [
  { value: "selling", label: "Продажа" },
  { value: "succession", label: "Правопреемство" },
  {
    value: "meeting_for_management",
    label: "ОСС на непосредственное управление",
  },
  { value: "meeting_for_other_company", label: "ОСС в иную УК" },
  { value: "meeting_for_cooperatives", label: "ОСС в ТСЖ/ЖСК/ТСН" },
  {
    value: "administration_resolution_izhs_result",
    label: "Постановление администрации: Дом признан частным(ИЖС)",
  },
  {
    value: "administration_resolution_emergency_condition",
    label: "Постановление администрации: Расселен/сгорел/снесен",
  },
  {
    value: "administration_resolution_ces_other",
    label: "Постановление администрации: КЧС/иное",
  },
  {
    value: "management_contract_cancellation",
    label: "Расторжение договора управления",
  },
  {
    value: "exploitation_contract_cancellation",
    label: "Расторжение договора эксплуатации",
  },
  {
    value: "tszh_exploitation_contract_cancellation",
    label: "Расторжение договора эксплуатации с ТСЖ",
  },
  { value: "interim_management", label: "Временное управление" },
  { value: "court_decision", label: "Решение суда" },
]

export const AGAINST_MEETING_RESULT_CHOICES = [
  { value: "in_favor", label: "В нашу пользу" },
  { value: "against", label: "Не в нашу пользу" },
  { value: null, label: "Неизвестно" },
]

export const AGAINST_MEETING_STATUS_CHOICES = [
  { value: "planned", label: "Планируется" },
  { value: "happens", label: "Идет" },
  { value: "passed", label: "Прошло" },
]

export const ASSESSMENT_CHOICES = [
  { value: null, label: "Не поставлена" },
  { value: "great", label: "Отлично" },
  { value: "average", label: "Средне" },
  { value: "bad", label: "Плохо" },
]

export const MEETING_STATUS_CHOICES = [
  { value: "accepted", label: "1. Принято в работу" },
  { value: "initiators_search", label: "2. Поиск инициаторов" },
  { value: "agenda_agreement", label: "3. Согласование повестки" },
  { value: "notification_mailing", label: "4. Отправка уведомлений" },
  { value: "in-person_part", label: "5. Начало очной части" },
  { value: "absentee_start", label: "6. Начало заочной части" },
  { value: "absentee_end", label: "7. Завершение заочной части" },
  { value: "forms_acceptance", label: "8. Прием бланков" },
  { value: "summation", label: "9. Подведение итогов" },
  {
    value: "protocol_preparation",
    label: "10. Подготовка протокола",
  },
  {
    value: "referral_sign_protocol",
    label: "11. Направление на подписание протокола",
  },
  { value: "protocol", label: "12. Протокол подписан" },
  {
    value: "meeting_did_not_take_place",
    label: "13. Собрание не состоялось (кворум не набран)",
  },
  { value: "request", label: "14. Подача заявки в гжи" },
  { value: "storage", label: "15. Отправка на хранение" },
  { value: "license", label: "16. Включено в лицензию" },
  {
    value: "suspension_licence_registration",
    label: "17. Приостановка внесения в лицензию",
  },
  {
    value: "licence_refusal",
    label: "18. Отказ о включении в лицензию",
  },
  {
    value: "exclusion_from_license",
    label: "19. Исключение из лицензии",
  },
]

export const MEETING_RESULT_CHOICES = [
  { value: "positive", label: "Положительный" },
  { value: "negative", label: "Отрицательный" },
]

export const ROSREEST_STATUS_CHOICES = [
  { value: "created", label: "Выписка не заказана" },
  { value: "requested", label: "В очереди на заказ" },
  { value: "search", label: "Поиск на Росреестре" },
  { value: "waiting", label: "Заказ передан в Росреестр" },
  { value: "download", label: "Загрузка выписки из Росреестра" },
  { value: "uploaded", label: "Выписка получена из Росреестра" },
  { value: "not_uploaded", label: "Не загружен" },
  { value: "parsing", label: "Парсинг выписки" },
  { value: "parsed", label: "Выписка обработана" },
  { value: "error", label: "Ошибка" },
  { value: "parsing_error", label: "Ошибка парсинга" },
  { value: "search_error", label: "Ошибка поиска" },
  { value: "download_error", label: "Ошибка загрузки" },
]

export const TERRITORIAL_UNIT_TYPE_CHOICES = [
  { value: "management_division", label: "Регион управления" },
  { value: "management_sector", label: "Филиал управления" },
  {
    value: "management_service",
    label: "Территориальное отделение управления",
  },
  { value: "exploitation_division", label: "Дивизион эксплуатации" },
  { value: "exploitation_sector", label: "Участок эксплуатации" },
  { value: "exploitation_service", label: "Служба эксплуатации" },
  {
    value: "not_territorial_unit",
    label: "Не является территориальным подразделением",
  },
]

export const FOUNDATION_TYPE_CHOICES = [
  {
    value: "contract_with_developer",
    label: "Договор с застройщиком",
  },
  { value: "regulation", label: "Постановление" },
  { value: "tender", label: "Конкурс" },
  { value: "vote", label: "Протокол голосования ОССЖ" },
  { value: "official_notice", label: "Служебная записка" },
  { value: "email", label: "Электронное письмо" },
]

export const FINANCIAL_UNIT_FUND_CHOICES = [
  { value: "product", label: "Продукт" },
  { value: "not_product", label: "Не продукт" },
]

// mdsobject
export const OBJECT_CATEGORYID_CHOICES = [
  { value: 1, label: "Пром. предприятия" },
  { value: 2, label: "Прочие объекты" },
  { value: 3, label: "Автостоянка" },
  { value: 4, label: "Инж.объекты передающиеся городу" },
  { value: 5, label: "Гараж" },
  { value: 6, label: "Жилой дом" },
  { value: 7, label: "Площадка/Микрорайон" },
  { value: 8, label: "Коммерческое здание" },
  { value: 9, label: "ЛВАУ (Авт.укрытие)" },
  { value: 10, label: "Объекты СКБ" },
  { value: 11, label: "Прочее уч. заведение" },
  { value: 12, label: "Отделение связи" },
  { value: 13, label: "Полиция, Охрана" },
  { value: 14, label: "Объект ГО" },
  { value: 15, label: "Поликлиника" },
  { value: 16, label: "Прочие СКБ" },
  { value: 17, label: "ДДУ" },
  { value: 18, label: "Школа" },
  { value: 19, label: "Спортивный объект" },
  { value: 20, label: "Инж. сооружения" },
  { value: 21, label: "Инж.объекты на балансе ГК ПИК" },
  { value: 22, label: "Сети и благоустройство к корп." },
  { value: 23, label: "Трансф. подстанция" },
  { value: 24, label: "Внутриплощ. сети" },
  { value: 25, label: "Прочие инж. сооруж." },
  { value: 26, label: "АТС" },
  { value: 27, label: "Коттедж" },
  { value: 28, label: "Таунхаус" },
  { value: 29, label: "Апартаменты" },
  { value: 30, label: "Фуд-корт" },
]

export const OBJECT_KINDID_CHOICES = [
  { value: 1, label: "Площадка" },
  { value: 2, label: "Микрорайон" },
  { value: 3, label: "Очередь" },
  { value: 4, label: "Корпус" },
  { value: 5, label: "Дом" },
]

export const OBJECT_TYPEID_CHOICES = [
  { value: 1, label: "Газоснабжение" },
  { value: 2, label: "Панель" },
  { value: 3, label: "Монолит" },
  { value: 4, label: "Кирпич" },
  { value: 5, label: "Сборно-монолитный" },
  { value: 6, label: "Другие" },
  { value: 7, label: "Водопровод" },
  { value: 8, label: "Водосток" },
  { value: 9, label: "Канализация" },
  { value: 10, label: "Электроснабжение" },
  { value: 11, label: "Теплоснабжение" },
  { value: 12, label: `ОИДП типа "Площадка"` },
  { value: 13, label: "удалить" },
  { value: 14, label: "Объекты здравоохранения" },
]

export const REALESTATE_KINDID_CHOICES = [
  { value: 1, label: "Квартира" },
  { value: 2, label: "НежилоеПомещение" },
  { value: 3, label: "Гараж" },
  { value: 4, label: "Апартаменты" },
  { value: 5, label: "ТаунхаусКоттедж" },
  { value: 6, label: "СКБ" },
  { value: 7, label: "КладовыеПомещения" },
]

export const STORAGE_CHOICES = [
  { value: "management_company_office", label: "Офис УК" },
  { value: "first_lift_company_archive", label: "Архив ПЛК" },
  { value: "service_organization", label: "Обслуживающая организация" },
  { value: "mounting_organization", label: "Монтажная организация" },
]

export const ROSREEST_TYPE_CHOICES = [
  { value: "building", label: "Здание" },
  { value: "premise", label: "Помещение" },
]

export const PREMISE_TYPE_CHOICES = [
  { value: "flat", label: "Квартира" },
  { value: "room", label: "Комната" },
  { value: null, label: "Данные отсутствуют" },
]
